const api_uri = "https://luvpong.com:446";

export const enum_api_uri = {
    api_uri: `${api_uri}`,

    // 공통 ------------------------------------------
    site_info: `${api_uri}/v1/site/site-info`,
    policy_list: `${api_uri}/v1/site/policy-list`,
    policy_detail: `${api_uri}/v1/site/policy/:policy_type`,
    address_list: `${api_uri}/v1/select-list/address`,
    address_list2: `${api_uri}/v1/select-list/address/:parent_local_code`,
    apply: `${api_uri}/v1/request`,

    //매니저리스트
    manager_list: `${api_uri}/v1/manager/manager-list`,

    //고객센터
    notice_list: `${api_uri}/v1/customer/notice-list`,
    notice_detail: `${api_uri}/v1/customer/notice-view/:list_no`,
    inquiry_list: `${api_uri}/v1/customer/inquiry-list`,
    inquiry_detail: `${api_uri}/v1/customer/inquiry-view`,
    inquiry_write: `${api_uri}/v1/customer/inquiry-write`,
    inquiry_edit: `${api_uri}/v1/customer/inquiry-modify`,
    inquiry_delt: `${api_uri}/v1/customer/inquiry-delete`,
    faq_list: `${api_uri}/v1/customer/faq-list`,
    faq_category: `${api_uri}/v1/select-list/faq`,
};