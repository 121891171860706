import { useDispatch } from "react-redux";
import { applyPop } from "../../store/popupSlice";


const FloatingBox = () => {
    const dispatch = useDispatch();

    return(
        <div className="floating_wrap">
            <button type="button" className="btn_love_request" onClick={()=>dispatch(applyPop(true))}>
                <strong>소개팅 신청</strong>
                <span>19,900원</span>
            </button>
        </div>
    );
};

export default FloatingBox;