import { PatternFormat } from "react-number-format";


type InputBoxProps = {
    className?: string;
    type: string;
    value: string;
    placeholder: string;
    id: string;
    password?: boolean;
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onPassViewHandler?: () => void;
    phoneInput?: boolean;
};

const InputBox = ({
    className = '',
    type,
    value = '',
    placeholder,
    id,
    password = false,
    onChangeHandler,
    onPassViewHandler,
    phoneInput = false,
}: InputBoxProps) => {
    return(
        <div className={`input_box ${className}`}>
            {phoneInput ?
                <PatternFormat 
                    format="###-####-####"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChangeHandler}
                    id={id}
                />
                :   <input 
                        type={type} 
                        value={value}
                        placeholder={placeholder}
                        id={id}
                        onChange={onChangeHandler}
                    />
            }
            {password && <button type="button" className="btn_view" onClick={onPassViewHandler}>비밀번호보기버튼</button>}
        </div>
    );
};

export default InputBox;