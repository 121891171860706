import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { enum_api_uri } from "../../config/enum";
import { errorMsgHandler } from "../../config/utils";
import { RootState } from "../../store/store";
import { confirmPop, inquiryAuthPop } from "../../store/popupSlice";
import { inquiryAuthData } from "../../store/commonSlice";
import InputBox from "../InputBox";
import ConfirmPop from "../../components/popup/ConfirmPop";


const InquiryAuthPop = () => {
    const dispatch = useDispatch();
    const popup = useSelector((state:RootState)=>state.popup);
    const [password, setPassword] = useState<string>('');
    const [passView, setPassView] = useState<boolean>(false);
    const [confirm, setConfirm] = useState(false);


    //팝업닫기
    const closePopHandler = () => {
        dispatch(inquiryAuthPop({inquiryAuthPop:false, inquiryAuthPopIdx: null}));
    };


    //비밀번호 input값 입력시
    const onPasswordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };


    //비밀번호 확인하기
    const onAuthHandler = () => {
        const body = {
            list_no: popup.inquiryAuthPopIdx,
            password: password
        };
        axios.post(enum_api_uri.inquiry_detail, body)
        .then((res) => {
            if (res.status === 200) {
                const data = res.data;
                const comment = data.comment ? data.comment : {};
                dispatch(inquiryAuthData({
                    list_no: data.list_no,
                    name: data.name,
                    subject: data.subject,
                    contents: data.contents,
                    w_date: data.w_date,
                    tel: data.tel,
                    comment
                }));
                closePopHandler();
            }
        })
        .catch((error) => {
            const err_msg = errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: err_msg,
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        });
    };


    return(<>
        <div className="flex_center pop_wrap inquiry_auth_pop">
            <div className="dim"></div>
            <div className="pop_cont">
                <div className="pop_tit">
                    <p className="tit"><strong>1:1 문의</strong></p>
                    <button type="button" className="btn_close" onClick={closePopHandler}>닫기버튼</button>
                </div>
                <div className="pop_box">
                    <div className="box">
                        <p>이 글은 비밀글입니다. <br/><span>비밀번호</span>를 입력하여 주세요.</p>
                        <InputBox 
                            className="pass_input"
                            type={passView ? 'text' : 'password'}
                            value={password}
                            placeholder="비밀번호"
                            id='password'
                            password={true}
                            onChangeHandler={onPasswordChangeHandler}
                            onPassViewHandler={()=>setPassView(!passView)}
                        />
                    </div>
                    <div className="bottom_btn_box flex_between">
                        <button type="button" className="btn2" onClick={closePopHandler}>취소</button>
                        <button type="button" className="btn" onClick={onAuthHandler}>확인</button>
                    </div>
                </div>
            </div>
        </div>

        {/* confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default InquiryAuthPop;