type SubContProps = {
    pageClassName: string;
    children: React.ReactNode;
};

const SubCont = ({pageClassName, children}: SubContProps) => {
    return(
        <div className="sub_content">
            <div className={pageClassName}>
                <section className="section">
                    <div className="section_inner">{children}</div>
                </section>
            </div>
        </div>
    );
};

export default SubCont;