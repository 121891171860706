import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, PersistConfig, persistStore } from 'redux-persist';
import commonReducer, { CommonState } from './commonSlice';
import popupReducer, { PopupState } from './popupSlice';

// 전체 state의 타입 정의
export interface RootState {
  common: CommonState;
  popup: PopupState;
}

const reducers = combineReducers({
  common: commonReducer,
  popup: popupReducer,
});

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  whitelist: ['common'],
  timeout: 500
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/REGISTER',
          'persist/FLUSH',
          'persist/PAUSE',
          'persist/PURGE'
        ],
      },
    }),
});

// store의 디스패치 타입 가져오기
export type AppDispatch = typeof store.dispatch;

// 비동기 액션의 타입 정의
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);

export default store;


