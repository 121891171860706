// 숫자형 콤마넣기
export function makeIntComma(intVal: number): string {
  intVal *= 1; // 숫자형 변경
  const option = {
      maximumFractionDigits: 4 // 최대 소수점 자리
  };
  const result = intVal.toLocaleString('ko-KR', option);
  return result;
}

// 에러 타입 정의
interface CustomError extends Error {
  response?: {
      data?: {
          msg?: string;
          error?: {
              errors: {
                  msg: string;
              }[];
          };
      };
  };
}

// api 에러메시지
export function errorMsgHandler(error: CustomError): string {
  let errorMsg = '';
  let errorType = '';

  if (error.message) {
      errorType = "javascript-api";
  }

  if (error.response && error.response.data) { // 일반 에러 객체 처리
      errorType = "custom";
  }

  if (error.response && error.response.data && error.response.data.error) { // validator 에러 객체 처리
      errorType = "express-validator";
  }

  if (errorType === "javascript-api") { // 일반 에러 객체 처리
      errorMsg = error.message;
  } else if (errorType === "custom") { // 일반 에러 객체 처리
      errorMsg = error.response!.data!.msg!;
  } else if (errorType === "express-validator") { // validator 에러 객체 처리
      errorMsg = error.response!.data!.error!.errors[0].msg;
  } else {
      errorMsg = '알 수 없는 오류가 발생했습니다.';
  }
  return errorMsg;
}
