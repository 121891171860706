import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface InquiryComment {
    list_no: number | null;
    name: string;
    content: string;
    w_date: string;
};

interface InquiryAuthData {
    list_no: number | null;
    name: string;
    subject: string;
    contents: string;
    w_date: string;
    tel: string;
    comment: Partial<InquiryComment> | null; // Partial로 정의
};

export interface CommonState {
    inquiryAuthData: InquiryAuthData;
};

const initialState: CommonState = {
    inquiryAuthData: {
        list_no: null,
        name: '',
        subject: '',
        contents: '',
        w_date: '',
        tel: '',
        comment: {
            list_no: null,
            name: '',
            content: '',
            w_date: '', 
        },
    },
};

const common = createSlice({
    name: "common", //state 이름
    initialState,
    reducers:{
        inquiryAuthData: (state, action: PayloadAction<Partial<InquiryAuthData>>) => {
            state.inquiryAuthData = {
                ...state.inquiryAuthData,
                ...action.payload,
                comment: action.payload.comment ?? state.inquiryAuthData.comment
            };
        },
    }
});

export const {
    inquiryAuthData,
} = common.actions;
export default common.reducer;