import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface ManagerPopListItem {
    list_no: number;
    m_id: string;
    name: string;
    open_talk: string;
    sns: string;
    txt: string;
    profile_image: string[];
};

interface InquiryComment {
    list_no: number | null;
    name: string;
    content: string;
    w_date: string;
};

export interface PopupState {
    //안내,알림 팝업
    confirmPop: boolean;
    confirmPopTit: string;
    confirmPopTxt: string;
    confirmPopBtn: number | null;

    //매니저상세 팝업
    managerPop: boolean;
    managerPopIdx: number | null;
    managerPopList: ManagerPopListItem[];

    //1:1문의 비밀번호확인 팝업
    inquiryAuthPop: boolean;
    inquiryAuthPopIdx: number | null;

    //소개팅신청 팝업
    applyPop: boolean;

    //로딩 팝업
    loadingPop: boolean;
};

const initialState: PopupState = {
    //안내,알림 팝업
    confirmPop: false,
    confirmPopTit: "",
    confirmPopTxt: "",
    confirmPopBtn: null,

    //매니저상세 팝업
    managerPop: false,
    managerPopIdx: null,
    managerPopList: [],

    //1:1문의 비밀번호확인 팝업
    inquiryAuthPop: false,
    inquiryAuthPopIdx: null,

    //소개팅신청 팝업
    applyPop: false,

    //로딩 팝업
    loadingPop: false,
};

const popup = createSlice({
    name: "popup", //state 이름
    initialState,
    reducers:{
        confirmPop: (state, action: PayloadAction<Partial<PopupState>>) => {
            state.confirmPop = action.payload.confirmPop ?? state.confirmPop;
            state.confirmPopTit = action.payload.confirmPopTit ?? state.confirmPopTit;
            state.confirmPopTxt = action.payload.confirmPopTxt ?? state.confirmPopTxt;
            state.confirmPopBtn = action.payload.confirmPopBtn ?? state.confirmPopBtn;
        },
        managerPop: (state, action: PayloadAction<Partial<PopupState>>) => {
            state.managerPop = action.payload.managerPop ?? state.managerPop;
            state.managerPopIdx = action.payload.managerPopIdx ?? state.managerPopIdx;
        },
        managerPopList: (state, action: PayloadAction<ManagerPopListItem[]>) => {
            state.managerPopList = action.payload;
        },
        inquiryAuthPop: (state, action: PayloadAction<Partial<PopupState>>) => {
            state.inquiryAuthPop = action.payload.inquiryAuthPop ?? state.inquiryAuthPop;
            state.inquiryAuthPopIdx = action.payload.inquiryAuthPopIdx ?? state.inquiryAuthPopIdx;
        },
        applyPop: (state, action: PayloadAction<Partial<boolean>>) => {
            state.applyPop = action.payload;
        },
        loadingPop: (state, action: PayloadAction<Partial<boolean>>) => {
            state.loadingPop = action.payload;
        },
    }
});

export const {
    confirmPop,
    managerPop,
    managerPopList,
    inquiryAuthPop,
    applyPop,
    loadingPop,
} = popup.actions;
export default popup.reducer;