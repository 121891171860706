import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import { applyPop, managerPop } from './store/popupSlice';
import history from './config/history';
import Layout from './components/layout/Layout';
import SubCont from './components/layout/SubCont';
import Popup from './components/popup/Popup';
import LoadingPop from './components/popup/LoadingPop';
import './assets/css/reset.css';
import './assets/css/main.css';
import './assets/css/content.css';
import './assets/css/breakpoint.css';
import './assets/css/common.css';

// 페이지 컴포넌트 지연 로딩
const Main = lazy(() => import('./pages/main/Main'));
const ManagerList = lazy(() => import('./pages/manager/ManagerList'));
const AllService = lazy(() => import('./pages/service/AllService'));
const Notice = lazy(() => import('./pages/service/notice/Notice'));
const NoticeDetail = lazy(() => import('./pages/service/notice/NoticeDetail'));
const Inquiry = lazy(() => import('./pages/service/inquiry/Inquiry'));
const InquiryWrite = lazy(() => import('./pages/service/inquiry/InquiryWrite'));
const Faq = lazy(() => import('./pages/service/faq/Faq'));
const Terms = lazy(() => import('./pages/terms/Terms'));

function App() {
    const dispatch = useDispatch();
    const popup = useSelector((state:RootState)=>state.popup);


    //뒤로가기시
    useEffect(() => {
        const listenBackEvent = () => {
            //소개팅팝업 열려있을때 뒤로가기차단, 팝업만 닫기
            if(popup.applyPop){
                dispatch(applyPop(false));
                window.history.go(1); // 뒤로 가기를 차단하기 위해 1단계 앞으로 이동
            }
            
            //매니저상세팝업 닫기
            dispatch(managerPop({managerPop:false, managerPopIdx: null}));
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
            }
        });

        return unlistenHistoryEvent;
    },[popup.applyPop]);


    //팝업이 열리면 배경 스크롤을 막음
    useEffect(() => {
        const hasOpenPop = Object.keys(popup).some((key) => key.endsWith('Pop') && popup[key as keyof typeof popup]);

        if (hasOpenPop) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [popup]);


    return (
        <div id="wrap">
            <Suspense fallback={<LoadingPop />}>
                <Routes>
                    {/* 메인 */}
                    <Route path="/" element={<Layout><Main /></Layout>} />

                    {/* 매니저리스트 */}
                    <Route path="/manager" element={<Layout><SubCont pageClassName="page_manager"><ManagerList /></SubCont></Layout>} />

                    {/* 고객센터 ------- */}
                    <Route path="/service" element={<Layout><SubCont pageClassName="page_service"><AllService><Outlet /></AllService></SubCont></Layout>}>
                        {/* 공지사항 */}
                        <Route path="notice" element={<Notice />} />
                        {/* 공지사항 - 상세 */}
                        <Route path="notice/:list_no" element={<NoticeDetail />} />

                        {/* 1:1문의 */}
                        <Route path="inquiry" element={<Inquiry />} />
                        {/* 1:1문의 - 수정 */}
                        <Route path="inquiry/:list_no" element={<InquiryWrite />} />
                        {/* 1:1문의 - 작성 */}
                        <Route path="inquiry/write" element={<InquiryWrite />} />

                        {/* FAQ */}
                        <Route path="faq" element={<Faq />} />
                    </Route>

                    {/* 이용약관 */}
                    <Route path="/terms/:terms_tit" element={<Layout><SubCont pageClassName="page_terms"><Terms /></SubCont></Layout>} />
                </Routes>
            </Suspense>

            {/* 팝업 */}
            <Popup />
        </div>
    );
}

export default App;
