type Option = {
    value: string;
    label: string;
    code?: string;
};

type SelectBoxProps = {
    className: string;
    value: string;
    id: string;
    onChangeHandler: (e: React.ChangeEvent<HTMLSelectElement>, code?: string) => void;
    hiddenTxt: string;
    options: Option[];
};

const SelectBox = ({
    className = '',
    value = '',
    id,
    onChangeHandler,
    hiddenTxt = '선택',
    options
}: SelectBoxProps) => {

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = options.find(option => option.value === e.target.value);
        onChangeHandler(e, selectedOption?.code);
    };

    return(
        <div className={className}>
            <select 
                id={id}
                value={value}
                onChange={handleChange}
            >
                <option value="" hidden>{hiddenTxt}</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SelectBox;