import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { enum_api_uri } from "../../config/enum";
import { errorMsgHandler } from "../../config/utils";
import { confirmPop, loadingPop } from "../../store/popupSlice";
import { inquiryAuthData } from "../../store/commonSlice";
import Header from "./Header";
import FloatingBox from "./FloatingBox";
import Footer from "./Footer";
import ConfirmPop from "../popup/ConfirmPop";


type PolicyItem = {
    subject: string;
    policy_type: string;
};

type LayoutProps = {
    children: React.ReactNode
};

const Layout = ({children}: LayoutProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [main, setMain] = useState(false);
    const [siteInfo, setSiteInfo] = useState({});
    const [policyList, setPolicyList] = useState<PolicyItem[]>([]);
    const [confirm, setConfirm] = useState(false);
    const [previousPath, setPreviousPath] = useState<string | null>(null);


    //페이지변경시
    useEffect(()=>{
        const path = location.pathname;

        //스크롤탑으로 이동
        window.scrollTo(0,0);

        //메인페이지인지 체크
        if(path == '/'){
            setMain(true);
        }else{
            setMain(false);
        }

        //1:1문의 -> 다른페이지로 이동시 inquiryAuthData 초기화
        if(!path.includes('/service/inquiry')){
            if(previousPath && previousPath !== path){
                dispatch(inquiryAuthData({list_no:null, name:'', subject:'', contents:'', w_date:'', tel:'', comment:{list_no: null,name: '',content: '',w_date: ''}}));
            }
        }
        //1:1문의 상세 -> 다른페이지로 이동시 inquiryAuthData 초기화
        if(!path.includes('/service/inquiry/')){
            if(previousPath && previousPath !== path){
                dispatch(inquiryAuthData({list_no:null, name:'', subject:'', contents:'', w_date:'', tel:'', comment:{list_no: null,name: '',content: '',w_date: ''}}));
            }
        }

        // 이전 경로 업데이트
        setPreviousPath(path);
    },[location]);


    //사이트정보 가져오기
    const getSiteInfo = () => {
        axios.get(enum_api_uri.site_info)
        .then((res) => {
            if (res.status === 200) {
                const data = res.data;
                setSiteInfo(data);
            }
        })
        .catch((error) => {
            const err_msg = errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: err_msg,
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        });
    };


    //약관리스트 가져오기
    const getPolicyList = () => {
        axios.get(enum_api_uri.policy_list)
        .then((res) => {
            if (res.status === 200) {
                const data = res.data;
                setPolicyList(data);
            }
        })
        .catch((error) => {
            const err_msg = errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: err_msg,
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        });
    };


    useEffect(()=>{
        getSiteInfo();
        getPolicyList();
    },[]);


    return(<>
        <div className="body_user">
            <Header subHeader={main ? false : true}/>
            <div className="content_wrap">{children}</div>
            <FloatingBox/>
            <Footer 
                className={main ? 'main_foot' : ''}
                info={siteInfo}
                policyList={policyList}
            />
        </div>

        {/* confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default Layout;