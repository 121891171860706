import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide, SwiperClass, SwiperRef } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { managerPop } from "../../store/popupSlice";
import { RootState } from "../../store/store";


type ManagerItem = {
    list_no: number;
    m_id: string;
    name: string;
    open_talk: string;
    sns: string;
    txt: string;
    profile_image: string[];
};

const ManagerPop = () => {
    const dispatch = useDispatch();
    const popup = useSelector((state:RootState)=>state.popup);
    const managerSliderRef = useRef<SwiperRef>(null);
    const [managerList, setManagerList] = useState<ManagerItem[]>([]);
    const [prevName, setPrevName] = useState<string>('');
    const [nextName, setNextName] = useState<string>('');


    //팝업닫기
    const closePopHandler = () => {
        dispatch(managerPop({managerPop:false, managerPopIdx: null}));
    };


    useEffect(()=>{
        setManagerList(popup.managerPopList);
    },[popup.managerPopList]);


    useEffect(()=>{
        if(popup.managerPopIdx !== null && managerList.length > 0){
            const idx = popup.managerPopIdx;
            managerSliderRef!.current!.swiper.slideTo(idx,0);

            if(idx === 0){
                const next = managerList[0].name;
                setNextName(next);
            }
        }
    },[popup.managerPopIdx, managerList]);


    //매니저슬라이드 변경될때
    const onManagerSlideChange = (swiper: SwiperClass) => {
        const realIndex = swiper.realIndex;

        let prev: string = '';
        if(realIndex > 0){
            prev = managerList[realIndex-1].name;
        }
        setPrevName(prev);

        let next: string = '';
        if(realIndex < managerList.length-1){
            next = managerList[realIndex+1].name;
        }
        setNextName(next);
    };


    return(
        <div className="flex_center pop_wrap manager_pop">
            <div className="dim"></div>
            <div className="pop_cont">
                <div className="pop_box">
                    <div className="pop_tit flex">
                        <p className="tit"><strong>러브퐁</strong> 매니저</p>
                        <button type="button" className="btn_close" onClick={closePopHandler}>닫기버튼</button>
                    </div>
                    <div className="slider_box">
                        <Swiper 
                            className={`manager_slider`}
                            slidesPerView={1}
                            spaceBetween={20}
                            // loop={true}
                            observer={true}
                            observeParents={true}
                            navigation={{nextEl: `.slider_box .swiper-button-next`,prevEl: `.slider_box .swiper-button-prev`}}
                            centeredSlides={true}
                            breakpoints={
                                {
                                    1316:{slidesPerView:'auto',spaceBetween:'5%',centeredSlides:true},//width >= 1316
                                    768:{slidesPerView:1,spaceBetween:20,centeredSlides:true},//width >= 768
                                }
                            }
                            ref={managerSliderRef}
                            modules={[Navigation]}
                            onSlideChange={onManagerSlideChange}
                        >
                            {managerList.map((value:{name:string, txt:string, sns:string, open_talk:string, profile_image:string[]}, i:number)=>{
                                return(
                                    <SwiperSlide key={`manager_${i}`}>
                                        <div className="box flex flex_bottom">
                                            <div className="img_box"><img src={value.profile_image[2]} alt="프로필사진" /></div>
                                            <div className="txt_box">
                                                <p className="name">{value.name}</p>
                                                <p className="txt" dangerouslySetInnerHTML={{__html:value.txt}} />
                                            </div>
                                        </div>
                                        <div className="link_box flex_between">
                                            <div className="btn_type1">
                                                <a href={value.sns} target={"_blank"}>SNS 바로가기</a>
                                            </div>
                                            <div className="btn_type2">
                                                <a href={value.open_talk} target={"_blank"}>오픈톡 상담</a>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <div className="btn_box flex_between">
                            <div className="swiper-button-prev"><span>{prevName}</span></div>
                            <div className="swiper-button-next"><span>{nextName}</span></div>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn_close" onClick={closePopHandler}>목록으로</button>
            </div>
        </div>
    );
};

export default ManagerPop;