import { useSelector } from "react-redux";
import { createPortal } from "react-dom";
import { RootState } from "../../store/store";
import ManagerPop from "./ManagerPop";
import InquiryAuthPop from "./InquiryAuthPop";
import ApplyPop from "./ApplyPop";
import LoadingPop from "./LoadingPop";


const Popup = () => {
    const popup = useSelector((state: RootState) => state.popup);


    return createPortal(
        <>
            {/* 매니저상세 팝업 */}
            {popup.managerPop && <ManagerPop />}

            {/* 1:1문의 비밀글 비밀번호확인 팝업 */}
            {popup.inquiryAuthPop && <InquiryAuthPop />}

            {/* 소개팅신청 팝업 */}
            <ApplyPop />

            {/* 로딩 팝업 */}
            {popup.loadingPop && <LoadingPop />}

        </>,
        document.getElementById('modal-root')!
    );
};

export default Popup;