import { useSelector, useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { confirmPop } from "../../store/popupSlice";
import { RootState } from "../../store/store";


type ConfirmPopProps = {
    closePop?: 'custom';
    onCloseHandler?: () => void;
    goBack?: number;
    onClickHandler?: () => void;
};

const ConfirmPop = (props: ConfirmPopProps) => {
    const popup = useSelector((state: RootState) => state.popup);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //팝업닫기
    const closePopHandler = () => {

        //팝업닫기 custom <ConfirmPop closePop="custom" onCloseHandler={팝업닫는함수} /> 형식으로 쓰면 원하는 팝업닫는함수사용 가능
        if (props.closePop === 'custom' && props.onCloseHandler) {
            props.onCloseHandler();
            dispatch(confirmPop({ confirmPop: false, confirmPopTit: '', confirmPopTxt: '', confirmPopBtn: null }));
        }

        //창 이동 <ConfirmPop goBack={1}/> 형식으로 쓰면 숫자만큼 확인후 뒤로감
        if (props.goBack) {
            navigate(-props.goBack);
            dispatch(confirmPop({ confirmPop: false, confirmPopTit: '', confirmPopTxt: '', confirmPopBtn: null }));
        }

        if (!props.goBack && !props.closePop) {
            dispatch(confirmPop({ confirmPop: false, confirmPopTit: '', confirmPopTxt: '', confirmPopBtn: null }));
        }
    };

    return createPortal(
        <>
            {popup.confirmPop &&
                <div className="flex_center pop_wrap confirm_pop">
                    <div className="dim"></div>
                    <div className="pop_cont">
                        <div className="pop_tit">
                            <p className="tit"><strong>{popup.confirmPopTit}</strong></p>
                            <button type="button" className="btn_close" onClick={closePopHandler}>닫기버튼</button>
                        </div>
                        <div className="pop_box">
                            <p className="f_18 tx_c bp30" dangerouslySetInnerHTML={{__html:popup.confirmPopTxt}}></p>
                            {popup.confirmPopBtn === 1 &&
                                <button type="button" className="btn w_100" onClick={closePopHandler}>확인</button>
                            }
                            {popup.confirmPopBtn === 2 &&
                                <div className="bottom_btn_box flex_between">
                                    <button type="button" className="btn2" onClick={closePopHandler}>취소</button>
                                    <button type="button" className="btn" onClick={()=>{
                                        if (props.onClickHandler) {
                                            props.onClickHandler();
                                        }
                                        closePopHandler();
                                    }}>확인</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>,
        document.getElementById('modal-root')!
    );
};

export default ConfirmPop;