import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo_foot.svg";


type PolicyItem = {
    subject: string;
    policy_type: string;
};

type FooterProps = {
    className: string;
    info: any;
    policyList: PolicyItem[];
};

const Footer = ({className, info, policyList}: FooterProps) => {
    const [number, setNumber] = useState([]);

    //사이트정보 site_tel 배열로 변환
    useEffect(()=>{
        if(info.site_num){
            const newNumber = info.site_tel.split(' / ');
            setNumber(newNumber);
        }
    },[info]);


    return(
        <footer id="footer" className={className}>
            <div className="footer_cont">
                <div className="inner">
                    <ul className="link_ul flex_wrap">
                        {/* <li><Link to='/service/notice'>공지사항</Link></li> */}
                        {/* <li><Link to='/terms/privacy-policy'>개인정보 보호정책</Link></li>
                        <li><Link to='/terms/personal-information-collection'>개인정보수집</Link></li>
                        <li><Link to='/terms/terms-of-use'>이용약관</Link></li> */}
                        {policyList.map((item,i)=>{
                            let link = '';
                            if(item.policy_type == '1'){ //개인 정보 수집 및 이용 동의
                                link = '/terms/consent-to-collection-and-use';
                            }
                            if(item.policy_type == '2'){ //이메일 무단 수집 거부
                                link = '/terms/prohibition-of-unauthorized-email-collection';
                            }
                            if(item.policy_type == '3'){ //개인정보수집
                                link = '/terms/privacy-policy';
                            }
                            if(item.policy_type == '4'){ //이용약관
                                link = '/terms/terms-of-use';
                            }
                            if(item.policy_type == '5'){ //개인정보 처리 위탁
                                link = '/terms/outsourcing-personal-information-processing';
                            }
                            return(<li key={`policy_${i}`}><Link to={link}>{item.subject}</Link></li>);
                        })}
                    </ul>
                    <div className="logo_box flex_between flex_bottom">
                        <img src={logo} alt='logo' />
                        <p className="copy">COPYRIGHT© 2024 lovepong <br/>ALL RIGHTS RESERVED.</p>
                    </div>
                    <div className="txt_box flex_between">
                        <div>
                            <p className="txt">{info.site_address}</p>
                            <ul className="txt_ul flex_wrap">
                                {info.site_num && <li>사업자번호 : {info.site_num}</li>}
                                {info.site_manager && <li>개인정보책임관리자 및 대표 : {info.site_manager}</li>}
                            </ul>
                        </div>
                        <div>
                            {number.map((num,i)=>{
                                return(<p key={`number_${i}`} className={`number${i > 0 ? ' underline' : ''}`}>{num}</p>);
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;