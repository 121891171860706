import { Link } from "react-router-dom";

type HeaderProps = {
    subHeader: boolean
};

const Header = ({subHeader}: HeaderProps) => {

    return(
        <header id="header">
            <div className={`header_inner${subHeader ? ' sub_header_inner' : ''}`}>
                {subHeader && <Link to='/' className="btn_main_go">러브퐁 알아보기</Link>}
                <h1 className="logo">
                    <Link to='/'>러브퐁</Link>
                </h1>
                <div className="gnb_wrap">
                    <nav>
                        <ul className="gnb">
                            <li className="btn_type1">
                                <Link to='/manager'>러브퐁 매니저</Link>
                            </li>
                            <li className="btn_type2">
                                <Link to='/service/notice'>고객센터</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
};

export default Header;