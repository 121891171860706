import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { enum_api_uri } from "../../config/enum";
import { errorMsgHandler } from "../../config/utils";
import { RootState } from "../../store/store";
import { confirmPop, applyPop } from "../../store/popupSlice";
import InputBox from "../InputBox";
import SelectBox from "../SelectBox";
import ConfirmPop from "../../components/popup/ConfirmPop";
import img_pop_txt from "../../assets/images/img_pop_txt.png";


type ValuesItem = {
    name: string;
    year: string;
    gender: string;
    address1: string;
    address2: string;
    tel: string;
};

type AddressItem = {
    local_code: string;
    sido_gugun: string;
};

const ApplyPop = () => {
    const dispatch = useDispatch();
    const popup = useSelector((state:RootState)=>state.popup);
    const [confirm, setConfirm] = useState(false);
    const [applyOkconfirm, setApplyOkConfirm] = useState(false);
    const [values, setValues] = useState<ValuesItem>({
        name: '',
        year: '',
        gender: '',
        address1: '',
        address2: '',
        tel: '',
    });
    const [addressList, setAddressList] = useState<AddressItem[]>([]);
    const [addressSelectedCode, setAddressSelectedCode] = useState<string | null>(null);
    const [addressList2, setAddressList2] = useState<AddressItem[]>([]);
    const [termsChecked, setTermsChecked] = useState<boolean>(false);


    // Confirm팝업 닫힐때
    useEffect(()=>{
        if(popup.confirmPop === false){
            setConfirm(false);
            setApplyOkConfirm(false);
        }
    },[popup.confirmPop]);


    //팝업닫기
    const closePopHandler = () => {
        setValues({
            name: '',
            year: '',
            gender: '',
            address1: '',
            address2: '',
            tel: '',
        });
        setTermsChecked(false);
        dispatch(applyPop(false));
    };


    // yearOptions 배열 생성
    const yearOptions = useMemo(() => {
        const currentYear = new Date().getFullYear(); // 현재 년도 구하기
        const startYear = currentYear - 19; // 시작 년도 설정 (현재 년도에서 19를 뺍니다.)
        const endYear = 1963; // 종료 년도 설정
        
        const yearsArray = [];
        
        for (let i = startYear; i >= endYear; i--) {
            yearsArray.push({ value: i.toString(), label: i.toString() + ' 년생' });
        }

        return yearsArray;
    }, []);


    //주소 시,도 리스트 가져오기
    const getAddress = () => {
        axios.get(enum_api_uri.address_list)
        .then((res) => {
            if (res.status === 200) {
                setAddressList(res.data);
            }
        })
        .catch((error) => {
            const err_msg = errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: err_msg,
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        });
    };


    //주소 구,군 리스트 가져오기
    const getAddress2 = (code:string) => {
        axios.get(enum_api_uri.address_list2.replace(':parent_local_code',code))
        .then((res) => {
            if (res.status === 200) {
                setAddressList2(res.data);
            }
        })
        .catch((error) => {
            const err_msg = errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: err_msg,
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        });
    };


    useEffect(()=>{
        getAddress();
    },[]);


    // addressOptions 배열 생성
    const addressOptions = useMemo(() => {
        return addressList.map(item => ({
            value: item.sido_gugun,
            label: item.sido_gugun,
            code: item.local_code,
        }));
    }, [addressList]);


    // addressOptions2 배열 생성
    const addressOptions2 = useMemo(() => {
        return addressList2.map(item => ({
            value: item.sido_gugun,
            label: item.sido_gugun,
        }));
    }, [addressList2]);


    //주소 시,도 선택시 해당지역 구,군 리스트 가져오기
    useEffect(()=>{
        if(addressSelectedCode){
            getAddress2(addressSelectedCode);
        }
    },[addressSelectedCode]);


    //input값 변경시
    const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, code?: string) => {
        const { id, value } = e.target;
        
        if (id in values) {
            let newValue = value;
            if(id == 'tel'){
                newValue = value.replace(/\D/g, '');
            }
            setValues((prevValues) => ({
                ...prevValues,
                [id]: newValue,
            }));
        }
        //성별 남자 선택시
        if(id == 'male'){
            setValues((prevValues) => ({
                ...prevValues,
                gender: '1',
            }));
        }
        //성별 여자 선택시
        if(id == 'female'){
            setValues((prevValues) => ({
                ...prevValues,
                gender: '2',
            }));
        }
        //주소 시,도 선택시
        if(code){
            setAddressSelectedCode(code);
        }
    };


    //신청하기 버튼 클릭시 입력값들 체크하기
    const validationHandler = () => {
        if(!values.name){
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: '이름을 입력해주세요.',
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        }
        else if(!values.year){
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: '나이를 선택해주세요.',
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        }
        else if(!values.gender){
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: '성별을 선택해주세요.',
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        }
        else if(!values.address1){
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: '거주지역을 선택해주세요.',
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        }
        else if(values.tel.length < 11){
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: '연락처를 입력해주세요.',
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        }
        else if(!termsChecked){
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: '이용약관에 동의해주세요.',
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        }
        else{
            onApplyHandler();
        }
    };


    //소개팅 신청하기
    const onApplyHandler = () => {
        const address = [`${values.address1} ${values.address2}`.trim()];
        const body = {
            name: values.name,
            year: values.year,
            gender: values.gender,
            address1: address,
            tel: values.tel,
        };
        axios.post(enum_api_uri.apply, body)
        .then((res) => {
            if (res.status === 200) {
                dispatch(confirmPop({
                    confirmPop: true,
                    confirmPopTit: '소개팅 신청 완료',
                    confirmPopTxt: '소개팅 신청이 완료되었습니다!',
                    confirmPopBtn: 1,
                }));
                setApplyOkConfirm(true);
            }
        })
        .catch((error) => {
            const err_msg = errorMsgHandler(error);
            dispatch(confirmPop({
                confirmPop: true,
                confirmPopTit: '알림',
                confirmPopTxt: err_msg,
                confirmPopBtn: 1,
            }));
            setConfirm(true);
        });
    };




    return(<>
        <div className={`pop_wrap request_pop${popup.applyPop ? ' on' : ''}`}>
            <div className="dim"></div>
            <div className="pop_cont">
                <div className="popup">
                    <h3>
                        <img src={img_pop_txt} alt="사랑에 퐁~ 빠지다"/>
                        <strong>러브퐁 소개팅 신청</strong>
                    </h3>
                    <div className="form_wrap">
                        <ul className="form_ul">
                            <li>
                                <h4>이름</h4>
                                <div className="input_wrap">
                                    <InputBox 
                                        type={'text'}
                                        value={values.name}
                                        placeholder="이름을 입력해주세요."
                                        id='name'
                                        onChangeHandler={onInputChangeHandler}
                                    />
                                </div>
                            </li>
                            <li>
                                <h4>나이</h4>
                                <div className="input_wrap">
                                    <SelectBox 
                                        value={values.year}
                                        id='year'
                                        className="select_style1"
                                        hiddenTxt="나이를 선택해주세요."
                                        onChangeHandler={onInputChangeHandler}
                                        options={yearOptions}
                                    />
                                </div>
                            </li>
                            <li>
                                <h4>성별</h4>
                                <div className="input_wrap flex_between">
                                    <div className="chk_gender chk_gender_man">
                                        <input type="radio" className="blind" id="male" name="gender" onChange={onInputChangeHandler}/>
                                        <label htmlFor="male">
                                            <span>남성</span>
                                        </label>
                                    </div>
                                    <div className="chk_gender chk_gender_woman">
                                        <input type="radio" className="blind" id="female" name="gender" onChange={onInputChangeHandler}/>
                                        <label htmlFor="female">
                                            <span>여성</span>
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <h4>현재 <b>거주지역</b>을 선택해주세요!</h4>
                                <div className="input_wrap flex_between">
                                    <SelectBox 
                                        value={values.address1}
                                        id='address1'
                                        className="select_style1"
                                        hiddenTxt="시/도"
                                        onChangeHandler={onInputChangeHandler}
                                        options={addressOptions}
                                    />
                                    <SelectBox 
                                        value={values.address2}
                                        id='address2'
                                        className="select_style1"
                                        hiddenTxt="구"
                                        onChangeHandler={onInputChangeHandler}
                                        options={addressOptions2}
                                    />
                                </div>
                            </li>
                            <li>
                                <h4>연락처</h4>
                                <div className="input_wrap">
                                    <InputBox 
                                        type={'text'}
                                        value={values.tel}
                                        placeholder="숫자만 입력해주세요."
                                        id='tel'
                                        onChangeHandler={onInputChangeHandler}
                                        phoneInput={true}
                                    />
                                </div>
                            </li>
                        </ul>

                        <div className="chk_wrap">
                            <div className="chk_box3">
                                <input type="checkbox" className="blind" id="termsChk" 
                                    onChange={(e)=>{
                                        const checked = e.currentTarget.checked;
                                        if(checked){
                                            setTermsChecked(true);
                                        }else{
                                            setTermsChecked(false);
                                        }
                                    }}
                                    checked={termsChecked}
                                />
                                <label htmlFor="termsChk">
                                    <i>이용약관</i> 및 <i>개인정보 처리방침</i>에 동의합니다.
                                </label>
                            </div>
                        </div>
                        <div className="btn_wrap">
                            <button type="submit" className="btn_request" onClick={validationHandler}>가입없이 소개팅 신청하기!</button>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn_close" onClick={closePopHandler}>닫기</button>
            </div>
        </div>

        {/* 소개팅신청완료 confirm팝업 */}
        {applyOkconfirm && <ConfirmPop closePop="custom" onCloseHandler={closePopHandler} />}

        {/* confirm팝업 */}
        {confirm && <ConfirmPop />}
    </>);
};

export default ApplyPop;